.addToBasketContainer {
    .ImgButWrap {
        .addToBag {
            font-family: var(--default-font-family);
            font-weight: var(--font-weight-bold);
        }
    }
}

.AltProdDet {
    #productDetails {
        .addtoBagWrap {
            .addToBasketContainer {
                a {
                    background-color: var(--fras-pale-green) !important;
                    color: var(--fras-black) !important;
                    border-radius: 4px;
                    padding: 15px 32px;
                    position: relative;
                    margin: 0;
                    text-transform: none;
                    font-family: var(--secondary-font-family);
                    font-weight: var(--font-weight-regular);
                    font-size: var(--subtitle-font-size);
                    letter-spacing: 0;

                    &:hover {
                        background-color: var(--fras-dark-green) !important;
                    }
                    span {
                        font-family: var(--default-font-family);
                    }
                }

                &.sizeSelected a {
                    background-color: var(--fras-pale-green) !important;
                    color: var(--fras-black) !important;

                    &:hover {
                        background-color: var(--fras-mid-green) !important;
                    }
                }

                span {
                    font-family: var(--secondary-font-family);
                    font-weight: var(--font-weight-bold);
                    font-size: var(--body-font-size);
                }
            }

            .addToBagInProgress {
                a::after {
                    background-color: var(--fras-mid-green) !important;
                    right: 25%;
                }
            }

            .addedToBag {
                a::after {
                    right: 0;
                    border-radius: 4px;
                    opacity: 0;
                    background-color: var(--fras-mid-green);
                }
            }
        }

        .PersVouchBasketContainer {
            a {
                background-color: var(--fras-pale-green) !important;
            }

            &.sizeSelected a {
                font-family: var(--secondary-font-family);
                font-weight: var(--font-weight-regular);
                background-color: var(--fras-pale-green) !important;

                &:hover {
                    background-color: var(--fras-mid-green) !important;
                }
            }
        }

        .personalisationContainer {
            #pnlPersonalisation {
                margin-top: 24px;
            }

            .personalisationActive,
            .pnlMyIdPersonalisation {
                .divPersAddToBasketWishlistWrapper {
                    display: flex;
                    flex-wrap: wrap;

                    #spanAddToBagWrapper {
                        a {
                            margin: 24px 0;
                            padding: calc(var(--padding-unit) * 3.75);
                            line-height: 1.25em;
                            font-size: var(--subtitle-font-size);
                            font-weight: var(--font-weight-regular);
                        }
                    }

                    #divPersaddToBasketContainer .ImgButWrap a {
                        position: relative;
                    }

                    #divPersAddToWishListContainer {
                        .sAddToWishListWrapper a {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
        }

        #divPersaddToBasketContainer,
        #pnlMyIdPersonalisation .addToBasketContainer {
            flex-basis: calc(100% - 43px);
        }

        .NonBuyableOverlay {
            height: 100%;

            .NonBuyableOverlayMessage {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                margin: 0;
                height: 100%;
            }
        }
    }
}

.brand-dior,
.brand-chanel,
.brand-bvlgari,
.brand-hermes {
    .AltProdDet #productDetails .addtoBagWrap .addToBasketContainer {
        .sizeSelected a,
        a:hover,
        a {
            background-color: var(--fras-black) !important;
            color: var(--fras-white) !important;
        }
    }
    .sticky-atb .sticky-atb--buysection .sizeSelected a.addToBag {
        background-color: var(--fras-black) !important;
        color: var(--fras-white) !important;
    }
}

.sticky-atb .sticky-atb--buysection {
    position: relative;
    .sizeSelected a {
        background-color: var(--fras-pale-green) !important;

        &.addToBag {
            background-color: var(--fras-pale-green) !important;
            color: var(--fras-black) !important;
            padding: 8px 15px;
            border-radius: 4px;
            position: relative;
            margin: 0;
            font-family: var(--default-font-family);

            &:hover {
                background-color: var(--fras-mid-green) !important;
            }
        }
    }

    .addToBag {
        background-color: var(--fras-pale-green) !important;
        padding: 8px 15px;
        border-radius: 4px;
        position: relative;
        margin: 0;
        text-transform: none;

        &:hover {
            background-color: var(--fras-dark-green) !important;
        }
    }

    .NonBuyableOverlayMessage {
        height: 100%;
        font-size: 12px;
        padding: 0;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.sAddToWishListWrapper {
    #aAddToWishList {
        display: flex;
        align-items: center;
    }

    .global-icon-wishlist svg:hover {
        fill: var(--fras-black);
    }

    .addedWishList svg path {
        fill: var(--fras-black);
    }
}

#sticky-atb-wrapper.stickyCTA-active {
    transition: top 0.5s ease-in-out;
    top: 0;

    .menu-search-shown & {
        top: 120px;

        @media (min-width: 768px) {
            top: 124px;
        }
    }
}

.AltProdDet #productDetails .PersVouchBasketContainer.addToBagInProgress a::after,
#productMyIdPerosonalisationPages .addtoBagWrap.addToBagInProgress a::after,
.sticky-atb .sticky-atb--buysection .addToBagInProgress a::after {
    background-color: var(--fras-mid-green);
    right: 25%;
}

.AltProdDet #productDetails .PersVouchBasketContainer.addedToBag a::after,
#productMyIdPerosonalisationPages .addtoBagWrap.addedToBag a::after,
.sticky-atb .sticky-atb--buysection .addedToBag a::after {
    right: 0;
    border-radius: 4px;
    opacity: 0;
    background-color: var(--fras-mid-green);
}

.AltProdDet .productVariantContainer .personalisationActive {
    margin-top: 20px;
    margin-bottom: 14px;
}

.BasketWishContainer {
    margin-top: 24px;
    margin-bottom: 24px;
}

.sticky-atb .sticky-atb--buysection a.addToBag:hover {
    background-color: var(--fras-dark-green) !important;
}
