/*checkout*/
#CheckoutSpaRoot {
    header {
        background-color: var(--fras-white);
        border-bottom: 1px solid var(--fras-mid-grey);
    }

    .CheckoutHeader .headerRightSecureCheckout {
        color: var(--fras-black);
    }

    footer {
        background-color: var(--fras-warm-light-grey);

        #footerStepMsg,
        .copyrightTextMob {
            color: var(--fras-black);
        }
    }

    .sectionGroup {
        h1 {
            font-family: var(--default-font-family);
            font-weight: var(--font-weight-bold);
            text-transform: capitalize;

            &::before {
                background-color: var(--fras-mid-grey);
            }
        }
    }

    .activeSection {
        .sectionGroup {
            h1 {
                &::before {
                    background-color: var(--fras-warm-grey);
                }
            }
        }
    }

    button {
        font-family: var(--default-font-family);
        background-color: var(--fras-pale-green) !important;
        color: var(--fras-black) !important;
        cursor: pointer;
        letter-spacing: 0;
        text-transform: capitalize;
        font-weight: var(--font-weight-bold);

        &:hover {
            background-color: var(--fras-mid-green) !important;
        }

        &::before {
            opacity: 0;
        }
    }

    .summaryWrap {
        .summaryheader {
            padding: 30px;

            @media (min-width: 1025px) {
                background-color: var(--fras-white);
                border: 1px solid var(--fras-mid-grey);
            }

            .summaryTitleText {
                font-family: var(--default-font-family);
                font-style: normal;
                color: var(--fras-black);
                font-weight: var(--font-weight-bold);
                text-transform: capitalize;
            }
        }

        .summaryTotal {
            border-color: var(--fras-mid-grey);

            .summaryTotalSpacer {
                border-color: var(--fras-mid-grey);
            }
        }

        .summaryList {
            border-color: var(--fras-mid-grey);

            .summaryListBrand,
            .summaryListTitle,
            .summaryListInfo,
            .summaryTotal div {
                font-family: var(--default-font-family);
            }
        }
    }
}
