nav#topMenu ul li.root.SportsDirect,
nav#topMenu ul li.root.Flannels,
nav#topMenu ul li.root.Usc,
.mobMenuGroup li.root.SportsDirect,
.mobMenuGroup li.root.SportsDirect > .mp-level > .menulevelheader,
.mobMenuGroup li.root.Flannels > .mp-level > .menulevelheader,
.mobMenuGroup li.root.Flannels,
.mobMenuGroup li.root.Usc > .mp-level > .menulevelheader,
.mobMenuGroup li.root.Usc {
    > a {
        background-size: contain;
        background-repeat: no-repeat;
        overflow: hidden;
        text-indent: 200%;
    }
}

nav#topMenu ul li.root.SportsDirect,
.mobMenuGroup li.root.SportsDirect,
.mobMenuGroup li.root.SportsDirect > .mp-level > .menulevelheader {
    > a {
        background-image: url("/images/marketing/frasers-hp-brand-logo2.svg");
    }
}

nav#topMenu ul li.root.Usc,
.mobMenuGroup li.root.Usc,
.mobMenuGroup li.root.Usc > .mp-level > .menulevelheader {
    > a {
        background-image: url("/images/marketing/usc-logo-black.svg");
    }
}

nav#topMenu ul li.root.SportsDirect > a {
    background-position: center;

    @media (min-width: 768px) and (max-width: 1440px) {
        background-size: 65%;
    }

    &::after {
        max-width: 68px;
        margin: auto;
    }
}

nav#topMenu ul li.root.Usc > a {
    background-position: center 11px;

    @media (min-width: 768px) and (max-width: 1440px) {
        background-size: 70%;
    }

    @media (min-width: 1440px) {
        background-position: center 5px;
    }

    &::after {
        max-width: 68px;
        margin: auto;
    }
}

nav#topMenu ul li.root.Flannels,
.mobMenuGroup li.root.Flannels,
.mobMenuGroup li.root.Flannels > .mp-level > .menulevelheader {
    > a {
        background-image: url("/images/marketing/frasers-hp-brand-logo1.svg");
    }
}

nav#topMenu ul li.root.Flannels > a {
    background-position: center -1px;

    @media (min-width: 768px) and (max-width: 1021px) {
        background-position: center 6px;
        background-size: 72%;
    }

    &::after {
        left: 0;
        right: 0;
        max-width: 89px;
        margin: auto;
    }
}

.mobMenuGroup li.root.SportsDirect {
    > a {
        background-position: -13px -3px;
        background-size: 102px;
        text-indent: -9999px;
    }

    > .mp-level > .menulevelheader > a {
        background-position: center;
        width: 100px;
        text-indent: -9999px;
    }
}

.mobMenuGroup li.root.Usc {
    > a {
        background-position: 0 5px;
        background-size: 75px;
    }

    > .mp-level > .menulevelheader > a {
        background-position: center;
        width: 100px;
    }
}

.mobMenuGroup li.root.Flannels {
    > a {
        background-position: 0;
    }

    > .mp-level > .menulevelheader {
        text-align: center;
        padding-left: 10px;

        > a {
            background-size: 85px;
            background-position: center;
            width: 100px;
        }
    }
}
