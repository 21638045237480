#HeaderGroup.errorHeader {
    height: 60px;
    position: relative;

    .TopNavBar {
        border-bottom: 1px solid var(--fras-warm-grey);
    }
}

.error-main {
    h1 {
        font-size: 2.4em;
        text-transform: capitalize;
        font-weight: var(--font-weight-bold);
    }
    h2 {
        font-size: 1.5em;
    }
    p,
    .error-list-options {
        font-size: 1.5em;
        padding-bottom: 10px;
    }
    .error-list-options {
        padding-left: 20px;

        li {
            padding-bottom: 5px;
            list-style: disc;
        }
        a {
            color: var(--fras-black);

            &:hover {
                color: var(--fras-brown-grey);
            }
        }
    }
    .error-nav {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 30px;
        max-width: 700px;

        a {
            background-color: var(--fras-warm-grey);
            border-radius: var(--border-radius);
            width: 100%;
            text-align: center;
            text-transform: capitalize;
            padding: calc(2.5 * var(--padding-unit));
            color: var(--fras-black);
            font-weight: var(--font-weight-bold);
            font-size: var(--body-font-size);

            &:hover {
                background-color: var(--fras-brown-grey);
            }
        }
    }

    @media screen and (min-width: 768px) {
        margin: 50px auto;
        padding: 0 calc(7.5 * var(--padding-unit));

        .error-nav a {
            width: calc(50% - 5px);
        }
    }
}

footer.error-footer {
    margin: 30px auto 0;
}

.socialLinksWrapper {
    max-width: 1200px;
    width: 75%;
    max-width: 500px;
    border-bottom: 0;
    margin: auto;
}

.socialLinksWrapper ul {
    display: flex;
}

.socialLinksWrapper li {
    display: flex;
    width: 25%;
    text-align: center;
    justify-content: center;
}

.socialLinksWrapper li a {
    background-image: url(/images/core/sd-elevate-icons-v6.svg);
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-size: 1286px 1548px;
    width: 50px;
    height: 50px;
}

.socialLinksWrapper li.socInstagram a {
    background-position: -1143px -742px;
    max-width: 50px;
}

.socialLinksWrapper li.socFacebook a {
    background-position: -1083px -742px;
    max-width: 50px;
}

.socialLinksWrapper li.socTikTok a {
    background-position: -1203px -742px;
    max-width: 50px;
}

.socialLinksWrapper li.socYouTube a {
    background-position: -1143px -814px;
    max-width: 50px;
}

@media screen and (min-width: 768px) {
    .socialLinksWrapper {
        width: 20%;
        margin: 0;
    }
}

.SddkError {
    display: none;
}

.currency-dkk .globalError {
    display: none;
}

.currency-dkk .SddkError {
    display: block;
}
