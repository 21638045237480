.WishListProductQuantityControls .TextQuantity span,
#WishListDisplay .saleprice {
    font-weight: var(--font-weight-regular);
}

#divWishList .wishlist-summary {
    top: 55px;

    .wishlist-summary-inner {
        margin-top: 0;
        max-height: calc(100vh - 55px);
    }

    .ul-container {
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: var(--fras-warm-light-grey);
        }

        &::-webkit-scrollbar-thumb {
            background: var(--fras-brown-grey);
            border-radius: 5px;
            border: none;
        }

        .wishItemContentWrap {
            span,
            div,
            button {
                font-weight: var(--font-weight-regular);
            }

            div.wishItemContentWrap-price span {
                font-weight: var(--font-weight-regular);
            }
        }

        .wishItemContentWrap-name span {
            -webkit-line-clamp: 1;
            /*stylelint-disable*/
            display: -webkit-box;
            /*stylelint-enable*/
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .wishlist-summary-header {
        padding: 30px 24px;

        .wishlist-summary-tick .tick-circle {
            fill: var(--fras-pale-green);
        }
    }

    .wishlist-summary-footer .ctaBtn {
        background-color: var(--fras-pale-green);
        color: var(--fras-black);
        text-transform: none;
        font-style: normal;
        font-family: var(--default-font-family);
        font-weight: var(--font-weight-bold);

        &:hover {
            background-color: var(--fras-mid-green);
            color: var(--fras-black);
        }

        &:focus {
            outline: 1px solid var(--fras-warm-grey) !important;
            outline-offset: 4px;
            border-radius: 4px;
            background-color: var(--fras-brown-grey);
        }

        &.signIn {
            font-weight: var(--font-weight-regular);
        }

        &.signIn:focus,
        &.signIn:hover {
            background-color: var(--fras-white);
            color: var(--fras-black);
            border: 1px solid var(--fras-brown-grey);
            border-radius: 4px;
        }
    }

    .wishlist-summary-header .wishlist-summary-count,
    .wishlist-summary-header h3 {
        font-family: var(--default-font-family);
        font-weight: var(--font-weight-bold);
        font-size: 18px;
        line-height: 25px;
    }
}

.wishlist-page {
    max-width: var(--default-container-max-width);
    margin: 16px auto 0;

    @media (min-width: 1640px) {
        max-width: var(--large-container-max-width);
    }

    .wishlist-page__header {
        z-index: 1;
        top: var(--cart-sticky-element-default-top-value);
        transition: top 0.4s ease-in-out;

        .global-icon-account svg path {
            stroke-width: 1px;
        }

        @media (max-width: 1021px) {
            top: 156px;

            .menu-search-hidden & {
                top: 0;
            }
        }

        @media (max-width: 767px) {
            top: 161px;
        }
    }

    h1,
    h1 span {
        font-family: var(--default-font-family);
        text-transform: capitalize;
        font-weight: var(--font-weight-bold);
    }

    .wishlist-page__body {
        @media (max-width: 1021px) {
            margin-top: 16px;
        }
    }

    .product-line-card a,
    .product-line-card p,
    .product-line-card div,
    .product-line-card label,
    .product-line-card__description-remove-button,
    .wishItemContentWrap-move-to-bag {
        font-size: var(--body-font-size);
        font-weight: var(--font-weight-regular);
    }

    .product-line-card__description-line-error-message {
        color: var(--fras-red);
    }

    .product-line-card__description-sizes select,
    .product-line-card__bottom-quantity {
        border: 1px solid var(--fras-warm-grey);
    }

    .product-line-card__bottom-quantity-input,
    .product-line-card__description-sizes select option {
        font-size: var(--subtitle-font-size);
        font-weight: var(--font-weight-regular);
    }

    .product-line-card__bottom-price span,
    .product-line-card__bottom-subtotal span {
        font-weight: var(--font-weight-regular);
        font-family: var(--default-font-family);
    }

    .product-line-card__bottom-quantity-minus,
    .product-line-card__bottom-quantity-plus {
        font-size: 22px;
        font-weight: var(--font-weight-regular);
        font-family: var(--default-font-family);
        padding: 0;
    }

    .product-line-card__description-remove button {
        align-items: center;
    }

    .remove-icon {
        width: 16px;
    }

    .wishlist-page__header h1 {
        text-transform: capitalize;
    }

    .wishlist-page__header::before {
        height: 16px;
        top: -16px;
    }

    .wishlist-page__primary-button {
        background-color: var(--fras-warm-grey);
        font-family: var(--default-font-family);
        color: var(--fras-black) !important;
        font-style: normal;
        text-transform: capitalize;
        letter-spacing: var(--default-letter-spacing);
        padding: 13px 32px;

        &:hover {
            background-color: var(--cart-button-transactional-hover-background-color);
        }
    }
}

.wishlist-share {
    border: 1px solid var(--fras-mid-grey);
    border-radius: 4px;

    .wishlist-share__header {
        border-radius: 4px 4px 0 0;

        h1 {
            display: flex;
            align-items: center;

            span {
                font-family: var(--default-font-family);
            }
        }
    }

    .wishlist-share__body {
        background: var(--fras-white);
        padding: 20px 25px 25px;
    }

    .wishlist-share__close {
        top: 18px;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;

        svg {
            width: 16px;

            path {
                stroke-width: 1px;
            }
        }
    }

    .wishlist-share__send-copy label {
        font-weight: var(--font-weight-regular);
        font-family: var(--default-font-family);
    }

    #share-wishlist-button {
        background-color: var(--fras-warm-grey);
        font-family: var(--default-font-family);
        color: var(--fras-black) !important;
        font-style: normal;
        text-transform: capitalize;

        &:hover {
            background-color: var(--fras-brown-grey);
        }
    }

    .wishlist-share__item {
        border: none;
        padding: 10px 0;
    }

    .wishlist-share__input-email {
        border: 1px solid var(--fras-warm-grey);
        color: var(--fras-warm-grey);
        border-radius: 4px;
        padding: 15px 10px;
        margin-bottom: 20px;
    }

    .wishlist-share__send-copy {
        margin-bottom: 28px;
    }

    .wishlist-share__message.wishlist-share__info {
        display: block;
        font-family: var(--default-font-family);
        padding-top: 20px;
    }
}

.wishlist-page__empty h1 {
    font-weight: var(--font-weight-regular) !important;
}

#divWishList .wishlist-summary .wishlist-summary-footer .ctaBtn {
    padding: 12px;
}
.wishlist-page .wishlist-page__primary-button {
    padding: 13px 32px !important;
}
