/* New footer */
.FooterGroupLink {
    width: 100%;
    float: none;
    padding: 0;

    li {
        line-height: 1.6em;
        padding: 0;

        a {
            padding: 5px 10px;
            font-size: var(--body-font-size);
            color: var(--fras-black);
            line-height: 2;
            font-weight: var(--font-weight-regular);
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

h2.FooterHeader {
    font-size: var(--subtitle-font-size);
}

.FooterMenuWrap {
    float: none;
}

.FooterGroup,
.dbc,
.FooterPane,
.FooterPane2 {
    margin: 0 auto;
    max-width: 1440px;
    display: block;
    float: none;
    position: relative;
    padding: 0 10px;
}

.FooterGroup {
    padding: 0;
    position: relative;
    overflow: visible;
    border-bottom: 1px solid var(--fras-mid-grey);

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid var(--fras-mid-grey);
        display: block;
        bottom: -1px;
    }

    &::before {
        right: 100%;
    }

    &::after {
        left: 100%;
    }
}

.FooterWrap {
    border-top: none;
    z-index: 1 !important;
    overflow: hidden;
    background-color: var(--fras-warm-light-grey);
    padding: 0;

    .FooterTop {
        display: table;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }

    .footerContainer .footerInfoSection .footerLangaugeSwitcher {
        width: 200px;
    }

    .FooterTop .SubTitle {
        display: block;
        color: var(--fras-black);
        font-weight: var(--font-weight-bold);
        text-align: center;
        font-size: 16px;
    }

    .FooterTop span.hidden-xs {
        display: block;
        color: var(--fras-white);
        margin-top: 5px;
        font-size: var(--subtitle-font-size);
    }

    .FooterTop span.hidden-xs span {
        text-decoration: underline;
    }
}

.FooterWrap .footerContainer .footerInfoSection .footerStoreFinder a::before,
.FooterWrap .footerContainer .footerInfoSection .footerCustServ a::before,
#topLinkMenu ul.TopLinkMenu .iconsStores a::before,
#topLinkMenu ul.TopLinkMenu .iconsCS a::before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: 934px 950px;
}

.FooterWrap .footerContainer .footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsStores a::before,
.footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsCS a::before {
    margin-right: 10px;
}

.FooterWrap .footerContainer .footerInfoSection .footerStoreFinder a::before,
#topLinkMenu ul.TopLinkMenu .iconsStores a::before {
    background-position: -175px -452px;
}

.FooterWrap .footerContainer .footerInfoSection .footerCustServ a::before,
#topLinkMenu ul.TopLinkMenu .iconsCS a::before {
    background-position: -24px -453px;
}

.FooterSubGroup .FooterHeader {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    color: var(--fras-black);
    border: none;
    float: none;
}

.FooterGroup4 {
    .FooterGroupLink a::before {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 10px;
        background-size: 766px 922px;
        width: 20px;
        height: 20px;
    }

    .FooterGroupLink li:nth-child(1) a,
    .FooterGroupLink li:nth-child(2) a,
    .FooterGroupLink li:nth-child(3) a,
    .FooterGroupLink li:nth-child(4) a,
    .FooterGroupLink li:nth-child(5) a {
        padding-left: 38px;
        position: relative;
    }

    .FooterGroupLink li:nth-child(1) a::before {
        background-position: -650px -444px;
    }

    .FooterGroupLink li:nth-child(2) a::before {
        background-position: -722.5px -446.5px;
    }

    .FooterGroupLink li:nth-child(3) a::before {
        background-position: -685.5px -446.5px;
    }

    .FooterGroupLink li:nth-child(4) a::before {
        background-position: -722px -404px;
    }

    .FooterGroupLink li:nth-child(5) a::before {
        background-position: -722px -491px;
    }
}

#FootcmdSearch {
    padding: 7px 4% 7px 6%;
}

#topLinkMenu ul.TopLinkMenu .iconsStores a {
    padding-top: 20px;
    padding-bottom: 20px;
}

.footerLogo {
    text-align: center;
    padding-top: 28px;
}

.FooterSearchCountry .FooterHeader h2 {
    color: var(--fras-mid-grey);
    font-size: 1.4em;
    border-bottom: 1px solid var(--fras-warm-light-grey);
    font-weight: var(--font-weight-bold);
}

.footerInfoSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid var(--fras-mid-grey);
}

.TopLinkMenu {
    display: flex;
    align-items: center;
    justify-content: center;
}

#topLinkMenu {
    width: 100%;

    ul li {
        text-align: left;
    }

    &:hover .TopLinkDrop .TopSubLinkMenu {
        max-height: 600px;
    }

    &.disable-transitions .TopSubLinkMenu {
        display: none;
        transition: none;
    }

    ul li.TopLinkDrop ul {
        width: 400px;
        display: none;
        padding: 0;
        right: 0;
        position: absolute;
        top: 48px;
    }

    ul li.TopLinkDrop ul::before {
        content: "";
        display: none;
        position: absolute;
        width: 24px;
        height: 13px;
        top: 0;
        right: 18px;
        border-bottom: 12px solid var(--fras-white);
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
    }

    ul li.TopLinkDrop ul:hover::before,
    ul li.TopLinkDrop a:hover + ul::before {
        display: block;
    }

    ul li.TopLinkDrop ul li,
    ul li.TopLinkDrop ul.TopSubLinkMenu li a {
        width: 100%;
        white-space: nowrap;
    }

    ul .TopLinkDrop {
        z-index: 80;
    }

    ul li.TopLinkDrop ul li {
        background-color: var(--fras-white) !important;
        border-bottom: 1px solid #efefef;
        border-left: 1px solid #efefef;
        border-right: 1px solid #efefef;
        line-height: 2.5em;
    }

    ul li.TopLinkDrop ul > li a .logoutTxt::after {
        content: "";
        display: block;
        height: 24px;
        width: 24px;
        background-size: 600px;
        background-position: -310px -376px;
        float: right;
    }

    ul li.TopLinkDrop ul li.FirstTopSub {
        border-top: 1px solid #efefef;
        margin-top: 12px;
    }

    ul li.TopLinkDrop ul li.AccOrderIcon {
        border-bottom: 1px solid #9a9a9a;
    }

    ul li.TopLinkDrop ul li.SignOut {
        text-align: center;

        a {
            display: inline-block;
            padding: 15px;
            text-align: left;
            background-color: var(--fras-warm-grey);

            &:hover {
                background-color: var(--fras-brown-grey);
            }

            span {
                color: var(--fras-black);
                font-family: var(--secondary-font-family);
                text-align: left;
            }
        }
    }

    ul.TopLinkMenu > li > a {
        font-size: 1em;
        line-height: 1em;
    }

    ul.TopLinkMenu > li > a span.logoutTxt {
        display: none;
        height: auto;
        margin-top: 3px;
        color: transparent;
    }

    ul.TopLinkMenu > li {
        width: auto;
        line-height: 55px;
        border: 0;
        position: relative;
        flex: 1 1 auto;
    }

    ul li.TopLink a {
        padding: 0;
        background-image: none;
        background-position: initial;
        background-repeat: no-repeat;
        height: 100%;
        display: block;
        color: #333;
    }

    ul li.TopLink > a {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;
    }

    .TopLinkMenu .TopLink a .loggedoutTxt {
        display: none;
    }

    .TopSubLinkMenu .user-name {
        font-size: 16px;
    }

    .TopSubLinkMenu .user-name a {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    ul.TopLinkMenu .iconsStores a span,
    ul.TopLinkMenu .iconsCS a span {
        display: block;
        font-size: var(--subtitle-font-size);
        color: var(--fras-black);
        font-weight: var(--font-weight-bold);
    }

    ul.TopLinkMenu > li span {
        color: var(--fras-black);
        font-size: 14px;
        display: inline-block;
        line-height: 1.4em;
    }

    ul.TopLinkMenu .TopSubLinkMenu > li span {
        width: 100% !important;
        vertical-align: middle;
        line-height: 1.5em;
    }

    ul li.TopLink > a > .ico {
        height: 26px;
        background-size: 750px 750px;
        background-position: -64px -404px;
        background-repeat: no-repeat;
        width: 22px;
        display: inline-block;
        vertical-align: middle;
    }

    .TopSubLinkMenu .ico {
        display: none;
    }

    .TopLinkMenu .TopSubLinkMenu li a {
        padding: 15px;
        background: none;
        background-color: var(--fras-white);
        transition: background-color 0.3s ease-in;
    }

    .TopLinkMenu .TopSubLinkMenu li a:hover {
        background-color: #f5fafc;
    }
}

#footApp,
#footAppGooglePlay {
    width: 50%;
    float: left;
    text-align: center;
}

#footApp a:hover > .ico {
    background-position: -662px -275px;
    width: 120px;
}

#footApp .ico {
    background-position: -662px -237px;
    width: 120px;
}

#footAppGooglePlay a:hover > .ico {
    background-position: -605px -1214px;
    width: 120px;
}

#footAppGooglePlay .ico {
    background-position: -605px -1174px;
    width: 120px;
}

.footerMenu .FooterSubGroup {
    background-color: transparent;
    min-height: 0;
    margin: 0;
    height: auto;
}

.footerPane .FooterHeader {
    padding: 10px 0;
    display: block;
    margin-bottom: 6px;
    width: 100%;
}

.FooterPane2 {
    margin: 0 auto;
    max-width: 1400px;
}

.Copyright {
    max-width: 1440px;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: calc(var(--padding-unit) * 3.75) calc(var(--padding-unit) * 5) calc(var(--padding-unit) * 5);
    }

    .footerCopyLine {
        width: 100%;
        padding-bottom: calc(var(--padding-unit) * 5);

        @media (min-width: 768px) {
            padding-bottom: 0;
        }
    }

    .payment-options {
        padding: 0;
        margin: 0;
    }

    .footerCopyLine a {
        display: none;
        color: var(--fras-dark-grey);
        font-size: 12px;
        text-decoration: underline;
        padding: 0 calc(var(--padding-unit) * 3);

        @media (min-width: 768px) {
            display: inline-block;
        }
    }

    .footerCopyLine span {
        color: var(--fras-dark-grey);
    }

    .footerPaymentLineBorder {
        padding: 15px 5px;
        width: 100%;

        @media (min-width: 768px) {
            padding: 0;
            text-align: right;
            width: auto;
            flex: 1 1 auto;
            max-width: 40%;
        }

        @media (min-width: 1022px) {
            max-width: none;
        }

        img {
            height: 40px;
        }
    }

    span {
        color: #888;
        font-size: 12px;
        margin: 0;
    }
}

.FooterWrap .footerContainer span.hidden-xs {
    display: block;
    color: var(--fras-white);
    margin-top: 5px;
    font-size: 1.2em;
}

.FooterWrap .footerContainer span.hidden-xs span {
    text-decoration: underline;
}

@media (max-width: 767px) {
    .footerelements {
        padding: 0;
    }

    .FooterHeader span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 18px;
        font-size: 11px;
        float: none;
        width: 30px;
        background-size: 850px 850px;
        background-position: -400px -385px;

        &.glyphicon-chevron-down {
            background-position: -439px -385px;
        }

        &::before {
            display: none;
        }
    }

    .FooterSubGroup .FooterHeader {
        padding: 15px 0;
        text-align: left;
        width: 100%;
        float: none;
        position: relative;
        display: inline-block;

        span {
            padding: 0;
            right: 0;
            width: 20px;
            height: 18px;
            background-position: -400px -436px;
            background-size: 900px 900px;

            &.glyphicon-chevron-down {
                background-position: -439px -436px;
            }
        }
    }

    .FootdvSearch {
        padding: 0 2%;
    }

    .FooterWrap .FooterTop .footerStoreFinder::after {
        display: none;
    }

    .footerQuickLinks {
        padding: 15px 15px 0;
    }

    .FooterGroup {
        border: none;
    }

    .FooterGroup::before,
    .FooterGroup::after {
        content: none;
        border: none;
        display: none;
    }

    .footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsStores a::before,
    .footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsCS a::before {
        margin: 0;
    }

    .FooterGroupLink {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 15px;

        li {
            text-align: left;
        }

        li a {
            padding: calc(var(--padding-unit) * 1.25) 0;
        }
    }

    .footerInfoSection {
        border: none;
    }

    .FooterWrap .FooterTop {
        .footerStoreFinder div::after {
            display: none;
        }

        .footerStoreFinder,
        .footerCustServ {
            text-align: center;
            padding-top: 10px;
        }

        .footerCustServ::after {
            top: 15px;
            bottom: 0;
        }

        .footerStoreFinder div,
        .footerCustServ div {
            padding: 0;
        }
    }

    .signup-wrapper {
        margin-top: 15px;

        .signupInner {
            padding: 20px 15px;
        }

        .signupInner .join-us-text {
            margin-bottom: 0;
            padding-top: 0;
            width: 100%;
        }
    }

    .FooterSubGroup {
        border-bottom: solid 1px #ddd;
        text-align: center;
    }
}

@media (min-width: 768px) {
    .FooterWrap .FooterTop {
        .footerStoreFinder,
        .footerCustServ {
            text-align: left;
        }

        .footerStoreFinder a,
        .footerCustServ a {
            display: block;
            position: relative;
            overflow: hidden;
        }

        .footerStoreFinder a::before,
        .footerCustServ a::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        .footerStoreFinder a div,
        .footerCustServ a div {
            display: block;
            padding-left: 55px;
        }

        > div {
            display: table-cell;
            float: none;
            vertical-align: middle;
            padding: 0 3%;
        }

        > div.signup-wrapper {
            padding: 0 3% 0 2%;
        }
    }

    .footerContainer .footerMenu {
        display: flex;
        justify-content: space-between;
        padding: 40px 20px;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        &::before,
        &::after {
            display: none;
        }

        > div {
            width: auto;
        }
    }

    .Copyright .footerCopyLine {
        width: auto;
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
        flex: 1 1 auto;
    }

    .FooterSubGroup {
        border-bottom: 0;
    }

    .FooterArea {
        margin-left: 20px;
    }

    .footerMenu > .row {
        width: 25%;
        float: left;
    }

    .footerPane {
        width: 50%;
    }

    #topLinkMenu ul.TopLinkMenu .iconsStores a {
        border-left: 1px solid var(--fras-mid-grey);
        border-right: 1px solid var(--fras-mid-grey);
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .footerLanguageSwitcher {
        width: 33.333333%;
    }

    .footerInfoSection #topLinkMenu {
        width: 66.666667%;
    }

    #topLinkMenu ul li.TopLink > a {
        flex-direction: row;
    }

    .FooterWrap .footerContainer .SubTitle {
        display: block;
        font-size: 0.9rem;
    }
}

@media (min-width: 768px) and (max-width: 1050px) {
    .FooterWrap .FooterTop > div {
        padding: 0 10px;
    }

    .footerContainer .footerMenu {
        justify-content: space-around;
    }
}

@media (min-width: 1022px) {
    .FooterInner > div {
        margin-bottom: 10px;
    }

    #topLinkMenu ul li.iconsCS a {
        justify-content: flex-start;
        margin-left: 50px;
    }

    #topLinkMenu ul li.TopLinkDrop ul.TopSubLinkMenu {
        opacity: 1;
    }
}

@media (min-width: 1336px) {
    #topLinkMenu ul li.iconsCS a {
        margin-left: 80px;
    }
}

@media (min-width: 1920px) {
    #topLinkMenu ul li.iconsCS a {
        margin-left: 150px;
    }
}

#divCookieAcceptance {
    background-color: #1c1c1c;
}

#cookieContentContainer {
    max-width: 2000px;
}

#divCookieAcceptance #inputAcceptCookies {
    background-color: var(--fras-white) !important;
    height: 20px;
    font-size: var(--body-font-size);
}

#divCookieAcceptance #cookieContentContainer p {
    color: var(--fras-white);
    text-align: left;
    font-size: 14px;
}

@media (min-width: 768px) {
    #divCookieAcceptance #cookieContentContainer p {
        text-align: center;
    }
}
