.sdDetails .colourImages li a {
    border: 1px solid var(--fras-mid-grey);
}

.sdDetails .colourImages li a:hover,
.sdDetails .colourImages li a:focus {
    border: 2px solid var(--fras-gold);
}

.sdDetails .colourImages {
    transition: height 0.3s ease-in-out;
    height: auto;
    overflow: hidden;
}

.sdDetails .colourImages li {
    width: 60px;
    height: 60px;
    margin: 0;
    padding: 5px;

    @media (max-width: 425px) {
        width: 70px;
        height: 70px;
    }
}

.sdDetails .colourImages li.swiper-slide {
    height: auto;
}

.sdDetails .colourImages li.variantHighlight a {
    border: 2px solid var(--fras-gold);
    border-radius: 4px;
}

.sdDetails .colourImages li.variantHighlight::before {
    display: none;
}

.sdDetails .colourImages li.greyOut span {
    background: #f8f8f8;
    opacity: 0.85;
}

.sdDetails .colourImages li a,
.sdDetails .colourImages li.greyOut a {
    border: 1px solid var(--fras-mid-grey);
    border-radius: 4px;
}

.sdDetails .colourImages li a .overlayColorway {
    cursor: pointer;
    background: rgba(231, 235, 237, 0.9);
    border-radius: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--fras-black);
    font-size: 13px;
}

#productDetails .colourImages li.greyOut.variantHighlight a,
#productDetails .sizeButtons li.sizeVariantHighlight.greyOut a {
    border-color: var(--fras-dark-grey) !important;
}

.dropdown-images {
    .image-dropdown button {
        background-color: var(--fras-white) !important;
        font-size: var(--body-font-size);

        &:focus {
            box-shadow: none;
        }
    }

    .image-dropdown ul {
        font-size: var(--body-font-size);
    }
}

#BuyColourText {
    font-weight: var(--font-weight-bold);
    font-size: var(--body-font-size);
}

#colourName {
    color: var(--fras-black);
    letter-spacing: var(--default-letter-spacing);
    font-size: var(--body-font-size);
    line-height: normal;
}

.sdDetails .colourImages.expandingColorways {
    max-height: none !important;
}
