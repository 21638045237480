#productImages {
    position: relative;
}

#productImages.ImageNotReady .ThumbProdWrap,
#productImages .ThumbProdWrap {
    position: relative;
    z-index: 1;
    opacity: 1;

    #productImageContainer {
        float: none;
    }
}

#productImages.ImageNotReady .ThumbProdWrap #productImageContainer::before,
#productImages.ImageNotReady .ThumbProdWrap #productImageContainer::after {
    content: "";
    display: block;
    position: absolute;
}

#productImages.ImageNotReady .ThumbProdWrap #productImageContainer::before {
    background-color: #efefef;
    opacity: 0.8;
    block-size: 100%;
    inline-size: 100%;
    z-index: 901;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
}

#productImages.ImageNotReady .ThumbProdWrap #productImageContainer::after {
    border-radius: 50%;
    block-size: 48px;
    inline-size: 48px;
    z-index: 902;
    inset-inline-start: 50%;
    inset-block-start: 50%;
    translate: -50% -50%;
    border-width: 3px;
    border-style: solid;
    border-color: var(--fras-black) var(--fras-black) var(--fras-white);
    animation: 1s loading-spinner linear infinite;
}

#productImages .ThumbProdWrap #productImageContainer .productRollOverPanel.swiper-container {
    margin: 0;
}

@keyframes loading-spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.sdPageProductContainer .productImageContentWrapper {
    display: flex;
}

@media (max-width: 767px) {
    .sdPageProductContainer .productImageContentWrapper {
        flex-wrap: wrap;
    }

    .sdDetails .productImageContentWrapper .product-header--mobile {
        flex: 0 0 100%;
    }

    #productImages {
        flex: 1 1 100%;
        width: 100%;
        padding: 0;
    }

    #productImages.ImageNotReady #productImageContainer .productImage.productImageGrid {
        display: flex;
        overflow: hidden;
    }

    #productImages.ImageNotReady #productImageContainer .productImage.productImageGrid .productRollOverPanel {
        flex: 0 0 90%;
    }

    #productImages .ThumbProdWrap {
        padding: 0;
    }

    .sdDetails .productRollOverPanel .swiper-scrollbar {
        bottom: 2px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 150px;
        height: 3px;
    }

    .sdDetails .productRollOverPanel .swiper-scrollbar.swiper-scrollbar-lock {
        display: none !important;
    }

    .sdDetails .productRollOverPanel .swiper-scrollbar .swiper-scrollbar-drag {
        background-color: var(--fras-gold);
    }
}
