.ControlWrap {
    .bagWishBlock,
    .accountBlock,
    .accountBlock .login,
    .accountBlock .AccountLink,
    .accountBlock .SignInLink {
        height: 100%;
    }

    .accountBlock .login {
        display: block;
    }

    .accountBlock .login .ico {
        display: inline-block;
        vertical-align: middle;
        font-size: 11px;
    }

    .accountBlock .login .hidden-xs {
        display: none;
    }
}

.SignInLink .login .userLoginContainer {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.Login {
    h1 {
        text-transform: capitalize;
        text-align: left;
        font-weight: var(--font-weight-bold);
    }

    .newlogin .myAccLogin .logIcon {
        background-position: -11px -214px;
    }

    label,
    h2,
    .strongTitle,
    .tcLogin a {
        font-weight: var(--font-weight-regular);
    }

    input {
        color: var(--fras-black);
        border-radius: var(--border-radius);
    }

    .ImgButWrap a span {
        font-family: var(--default-font-family);
    }

    @media (min-width: 768px) {
        .Login .innerBorder {
            padding-bottom: 114px;
        }
    }
}

.SignupConfirm .NewsletterFree,
.SignupConfirm em,
.SignupConfirm a {
    font-weight: var(--font-weight-bold);
}

.Registration select,
#RegistrationSubmit {
    border-radius: var(--border-radius);
}

.login {
    &::before {
        width: 80%;
        background-position: -6px -202px;
    }

    &:hover::before {
        background-position: -70px -201px;
    }
}

.loginContainer .ForgotPass a.ForgotPasswordLinkButton {
    font-weight: var(--font-weight-bold);
}

#accountMenu {
    .sidebar-nav li.active a,
    .cprefs section h2 {
        color: var(--fras-warm-grey);
    }

    ul.sidebar-nav li.FirstOptionAcc a {
        font-weight: var(--font-weight-bold);

        &:hover {
            background-color: var(--fras-brown-grey);
        }
    }

    .sidebar-nav li.active a,
    .sidebar-nav li a {
        font-weight: var(--font-weight-regular);
    }

    .ImgButWrap a {
        font-family: var(--secondary-font-family);
    }

    ul.sidebar-nav li.SignOutAcc a {
        font-size: var(--body-font-size) !important;
    }
}

#accountMenu .sidebar-nav li.active a::before,
#accountMenu ul.sidebar-nav li.SignOutAcc a,
.cprefs .yesnoSelect label.selected,
.cprefs .cprefsFrequency label.selected {
    background-color: var(--fras-warm-grey);
}

.Profile .Sharebutton a {
    background-color: var(--fras-warm-grey);
    color: var(--fras-black) !important;
    font-family: var(--secondary-font-family);
}

.NewCustWrap {
    .ImgButWrap {
        border-radius: 4px !important;

        a {
            border-radius: 4px;
            padding: 19px 16px;

            span {
                text-transform: capitalize;
            }

            &::after {
                display: none !important;
            }
        }
    }

    @media (min-width: 768px) {
        bottom: 34px;
        left: 30px;
        max-width: 230px;
    }
}

.ProfileWrap {
    h1,
    h2,
    label {
        font-weight: var(--font-weight-bold);
    }

    label {
        font-size: var(--body-font-size);
    }
}

.Profile {
    .ImgButWrap a,
    #hypEditAddress {
        font-family: var(--secondary-font-family);
    }

    #BasketDiv .BagandCheckoutBtn .secondary2 a {
        background-color: var(--fras-warm-grey) !important;
        border: none;
    }

    #BasketDiv .BagandCheckoutBtn .secondary2 a:hover {
        background-color: var(--fras-brown-grey) !important;
    }

    .accountWrapper {
        .accountBox h1,
        .logoutBox h1 {
            color: var(--fras-black);
            font-weight: var(--font-weight-bold);
        }

        .accountBox:hover,
        .logoutBox:hover {
            background-color: var(--fras-warm-grey);

            h1,
            p {
                color: var(--fras-black);
            }
        }

        @media (max-width: 767px) {
            .logoutBox {
                background-color: var(--fras-warm-grey);

                .logOut {
                    background-position: 68px -161px;
                }
            }
        }

        .accountBox,
        .accountBox:hover {
            .personalInfo {
                background-position: 250px -461px;

                @media (min-width: 768px) {
                    background-position: -90px -298px;
                }
            }

            .addressBook {
                background-position: 216px -461px;

                @media (min-width: 768px) {
                    background-position: -157px -298px;
                }
            }

            .orderHistory {
                background-position: 142px -459px;

                @media (min-width: 768px) {
                    background-position: -290px -298px;
                }
            }

            .changePassword {
                background-position: 43px -462px;

                @media (min-width: 768px) {
                    background-position: -470px -298px;
                }
            }

            .wishList {
                background-position: 178px -460px;

                @media (min-width: 768px) {
                    background-position: -224px -298px;
                }
            }

            .contactPreferences {
                background-position: 107px -462px;

                @media (min-width: 768px) {
                    background-position: -358px -298px;
                }
            }
        }
    }
}

#addressForm {
    label {
        font-weight: var(--font-weight-bold);
        font-size: var(--body-font-size);
    }
}

.OrderHistorySummaryRange li a:hover,
.OrderHistorySummaryRange li.selected a,
.NoOrdersFound {
    color: var(--fras-black);
    font-weight: var(--font-weight-bold);
}
