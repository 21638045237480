.mp-menu > .mp-level ul li.newTag .menuitemtext::after,
.mp-menu > .mp-level ul li.saleTag .menuitemtext::after,
.mp-menu > .mp-level ul li.hotTag .menuitemtext::after {
    content: "NEW";
    padding: 3px;
    vertical-align: text-bottom;
    font-size: var(--body-font-size);
    line-height: 1em;
    color: var(--fras-white);
    background: var(--fras-gold);
    display: inline-block;
}

.mp-menu > .mp-level ul li.saleTag .menuitemtext::after {
    content: "SALE";
    background: var(--fras-red);
}

.mp-menu > .mp-level ul li.hotTag .menuitemtext::after {
    content: "HOT";
    background: var(--fras-red);
}

#mp-menu .mp-level.mp-level.show-level {
    overflow-y: auto;
}

#mp-menu .mp-level.show-level.child-open {
    overflow-y: hidden;
}

#mp-menu .mp-level .MenusaleRed,
#mp-menu .mp-level .MenusaleRed > .menuitemtext.MobMenChevron {
    background-color: var(--fras-red);
    color: var(--fras-white);
}

.mp-container .mp-menu .mp-level #homeMenu li > a.mp-close,
.mp-container .mp-menu .mp-level ul > li a.mp-close {
    display: none;
}

.mp-container .mp-menu .mp-level {
    max-height: 100%;
    z-index: 2;
}

.mp-menu .mp-level .MenusaleRed > a.MobMenChevron::after {
    background-position: -393px -318px;
}

.mp-container .mp-menu .mmHasChild .mp-level.show-level .mobMenGroup {
    background-color: var(--fras-white);
    display: block;
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup {
    display: flex;
    flex-wrap: wrap;
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup > li {
    width: 100%;
    order: 1;
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup > li.leftHeader {
    order: 2;
    margin-top: 0;
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup > li.leftHeader.altHeader {
    margin-top: 20px;
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup > li.mobMenToplink {
    width: auto;
}

.mobMenuGroup #mob-myaccount .menulevelheader a {
    color: var(--fras-white);
}

#mp-menu .mp-level {
    background-color: var(--fras-white) !important;
    height: 100%;
    top: 0;
}

#mp-menu .mp-level .level1 .mp-level {
    top: 0;
    height: 100%;
}

#mp-menu > .mp-level {
    top: 0;
    height: 100%;
}

#mp-menu .mp-level .menuitemtext {
    color: var(--fras-black);
    font-size: var(--subtitle-font-size);
    box-shadow: none;
    cursor: pointer;
    letter-spacing: var(--default-letter-spacing);
    font-weight: var(--font-weight-bold);
}

#mp-menu .mp-level .menuitemtext > img {
    background-color: #f1f1f1;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
    display: inline-block;
    overflow: hidden;
}

.mp-menu > .mp-level a.menuitemtext,
.mp-menu > .mp-level #divCurrencyLanguageMobile a,
.mp-menu > .mp-level #divCurrencyLanguageMobile .currencySelectorMobile > li > .currencyOption {
    margin-left: 25px;
    padding-left: 0 !important;
    width: calc(100% - 25px);
}

.mp-menu ul li:last-of-type > a,
.mp-menu .shop ul li.MenuGroupOutlet > a,
.mp-menu .mobile-menu-footer-content > ul > li.has-dropdown > a.open,
.mp-menu .mobile-menu-footer-content #divCurrencyLanguageMobile ul > li.has-dropdown > a.open {
    box-shadow: none;
}

#mp-menu .mp-level .shopbySize > a::before,
#mp-menu .mp-level .shopbySize > .mp-level > p > a::before {
    content: "Shop by Size - ";
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul > .sdmColGap {
    margin-top: 0;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul {
    display: flex;
    flex-direction: column;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul > li {
    order: 5;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .mens-sale-menu {
    order: 1;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .womens-sale-menu {
    order: 2;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .kids-sale-menu {
    order: 3;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .size-sale-menu {
    order: 4;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .size-sale-menu > a::before,
#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .size-sale-menu p.menulevelheader > a::before {
    content: "Sale by ";
}

.mobMenuGroup li.root .mp-level > .menulevelheader {
    text-align: center;
    padding: 0 80px;
    min-height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MobileMenuContentWrap {
    .menu-header {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 60px;
    }

    #mob-account > .menuitemtext {
        position: absolute;
        top: 0;
        height: 60px;
        width: 60px;
        margin: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        padding: 0;
        overflow: hidden;
        white-space: nowrap;
        text-indent: -9999px;

        &::after {
            content: "";
            background-image: var(--spritesheet);
            display: block;
            height: 50px;
            width: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    #mob-account > .menuitemtext {
        left: calc(50% - 60px);

        &::after {
            background-position: -8px -321px;
            background-size: 700px 700px;
        }
    }
}

@media (max-width: 767px) {
    #mp-menu {
        height: calc(100% - 120px);
        width: 100%;
        z-index: 5895 !important;
        top: 120px !important;
        max-width: none;
    }

    .PullMenuActive .MenuCloseActive,
    .body-menu-open .MenuCloseActive {
        right: 0;
    }

    .ToplinksGroup .container-fluid > .row > div {
        background-color: var(--fras-white);
        height: 55px;
    }

    #mobMenuContainer {
        width: 50px;
        padding: 0;
    }

    #mobMenuContainer .menu-trigger {
        background: none !important;
    }

    .ToplinksGroup .container-fluid .ControlWrap {
        width: 210px;
    }

    .ControlWrap > div {
        float: left;
    }

    #mobMenuContainer {
        padding-right: 0;
    }

    #mp-menu .mp-level .mp-level .mp-level .MobMenChevron {
        margin: 0;
        padding-left: 40px !important;
        width: 100%;
        font-weight: var(--font-weight-regular);
    }

    .am-menu .am-level a.MobMenChevron::after,
    .mp-menu .mp-level a.MobMenChevron::after {
        background-size: 750px 750px;
        background-position: -393px -362px;
        top: 50%;
        transform: translateY(-50%);
    }

    #mp-menu .mp-level .mp-level .mp-level .sdmColHeader > .MobMenChevron {
        font-weight: var(--font-weight-bold);
        padding-left: 25px !important;
    }

    .accountBlock #topLinkMenu .TopLinkDrop .TopSubLinkMenu {
        display: none !important;
    }

    .currencyHeaderMob,
    .languageHeaderMob {
        text-transform: none;
        letter-spacing: var(--default-letter-spacing);
        font-size: var(--subtitle-font-size);
        display: block !important;
    }

    .MobileMenuContentWrap .shop > ul .mobMenToplink {
        display: block;
    }

    #mp-menu ul li.mobMenToplink {
        position: absolute;
        margin: 0;
        top: 18px;
        right: 25px;
        width: auto;
        background-color: transparent;
        height: auto !important;
    }

    #mp-menu ul li.mobMenToplink a.menuitemtext {
        color: #000;
        padding: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
        font-size: 14px;
        border-bottom: 2px solid #000;
        line-height: 1.4em;
        margin: 0;
        font-weight: var(--font-weight-bold);
        width: auto;
    }
}

@media (max-width: 1021px) {
    .MobileMenuContentWrap {
        background-color: var(--fras-warm-light-grey);

        .mobile-menu-footer-content {
            .shop {
                background-color: var(--fras-warm-light-grey);

                li a {
                    background-color: transparent;
                    box-shadow: none;
                }
            }
        }

        .shop {
            background-color: var(--fras-white);

            .mobMenuGroup {
                padding: 0;
            }
        }

        .shop,
        .mobMenuGroup {
            padding: 0;
        }

        .mobMenuGroup {
            li:not(.saleRed) {
                border-bottom: 1px solid var(--fras-mid-grey);
            }
        }
    }

    .MobileMenuContentWrap #topLinkMenu ul li.TopLink > a > .ico {
        background-position: -9px -341px;
        width: 50px;
        height: 50px;
    }

    #divCurrencyLanguageMobile #liMobileLanguageSelector {
        border-bottom: 1px solid var(--fras-mid-grey);
    }

    .mobileSelectedLanguage .languageRoot {
        padding-left: 0;
    }
}
