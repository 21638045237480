.BasketNew {
    a.ContinueOn {
        background-color: var(--fras-pale-green) !important;

        &:hover {
            background-color: var(--fras-mid-green) !important;
        }

        &::after {
            background: var(--spritesheet) no-repeat !important;
            background-size: 600px 600px !important;
            background-position: -310px -291px !important;
        }
    }

    .BasketQuantBut {
        &::before {
            background-image: var(--spritesheet) !important;
            background-size: 600px 600px !important;
        }

        &.s-basket-minus-button::before {
            background-position: -287px -284px;
        }

        &.s-basket-plus-button::before {
            background-position: -260px -284px !important;
        }
    }

    .deleteItemIcon {
        background-image: var(--spritesheet) !important;
        background-size: 600px 600px !important;
        background-position: -361px -286px !important;
    }

    .MainOrderSummary {
        .PromoCodeBut {
            border: none;

            a {
                background-color: var(--fras-black) !important;
                color: var(--fras-white);

                &:hover {
                    background-color: var(--fras-black) !important;
                    color: var(--fras-white);
                }
            }
        }
    }
}

.Basket {
    h1,
    h2 {
        font-family: var(--secondary-font-family);
        font-weight: var(--font-weight-bold);
    }

    #divContinueSecurely.ImgButWrap a,
    #divContinueSecurelyTop.ImgButWrap a {
        font-weight: var(--font-weight-regular);
    }

    .elevated-cart {
        margin-top: 16px;
    }

    .elevated-cart .basket-summary-header,
    .elevated-cart .PromoCodeBut,
    .basket-summary-continue-button-container .ContinueOn,
    .elevated-cart.elevated-cart-is-empty .cart-page__primary-button {
        text-transform: capitalize;
        letter-spacing: var(--default-letter-spacing);
    }

    .elevated-cart .basket-header,
    .elevated-cart .basket-summary-header {
        border-bottom: 1px solid var(--fras-warm-grey);
    }

    .elevated-cart .PromoCodeInput input {
        background: var(--fras-warm-light-grey);
    }
}

.BasketNew #BasketDiv table th.itemtotalprice .money,
.BasketNew .productdesc .competition-entry .terms,
.OrderSumm .TotalSumm span {
    font-weight: var(--font-weight-regular);
}

.basket-summary-continue-button-container .ContinueOn {
    padding: 14px 32px;
}

.Basket .elevated-cart.elevated-cart-is-empty .cart-page__primary-button {
    padding: 13px 32px;
}
