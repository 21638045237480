.ProdDetails,
#hotspotModal {
    .printess-button-cta {
        font-size: var(--body-font-size);
        text-transform: capitalize;
        border-radius: 4px;
        font-weight: var(--font-weight-regular);
        height: 46px;

        &:hover {
            border-color: var(--fras-warm-grey);
        }
    }

    .personalisation-applied {
        border-radius: 0;

        .printess-pdp-global-icon .global-icon-tick .tick-inner {
            fill: var(--printess-pdp-tick-inner) !important;
            stroke: var(--printess-pdp-tick-inner);
        }
        .printess-pdp-personalised-info-edit {
            border-radius: 4px;
        }
    }
}

.stockLevelWrapper + .printess-container {
    margin-top: 16px;
}

@media (max-width: 767px) {
    #printess-personalisation-modal .printess-personalisation-modal-dialog {
        width: calc(100% - 32px);
    }
}
