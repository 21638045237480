.cobrand-banner {
    display: none;
    top: 120px;
    background-color: var(--fras-white);
    z-index: 10;
    width: 100%;
    position: sticky;
    padding: calc(4 * var(--padding-unit)) 0;
    transition: top ease-in-out 0.5s;

    @media (min-width: 1022px) {
        margin: calc(var(--padding-unit) * 4) auto;
    }

    @media (min-width: 1920px) {
        max-width: 1880px;
    }

    @media (min-width: 1022px) and (max-width: 1639px) {
        padding: 0 calc(var(--padding-unit) * 10);
    }

    @media (min-width: 1640px) {
        padding: 0;
    }

    .cobrand-banner__wrapper {
        display: flex;
        width: 100%;
        background-color: var(--brand-banner-background);
        align-items: center;
        justify-content: center;
        height: 52px;

        @media (min-width: 1022px) {
            width: 100%;
            margin: 0;
            height: 64px;
        }

        .cobrand-banner__brand {
            display: var(--logo-display, none);
            max-width: var(--brand-logo-width);
        }
    }
}

.plp-cobrands-active.brand-flannels,
.plp-cobrands-active.brand-game,
.plp-cobrands-active.brand-evans,
.plp-cobrands-active.brand-sportsdirect,
.plp-cobrands-active.brand-jackwills,
.plp-cobrands-active.brand-hermes,
.plp-cobrands-active.brand-dior,
.plp-cobrands-active.brand-chanel {
    .cobrand-banner {
        display: flex;
        transition: top 0.5s ease-in-out;

        @media (max-width: 767px) {
            translate: 0;
            top: 120px;
        }

        @media (min-width: 768px) {
            translate: 0;
            top: 124px;
        }
    }

    .mobile-sort-and-filter-wrapper {
        top: 120px;
        transition: top 0.5s ease-in-out;

        @media (min-width: 768px) and (max-width: 1021px) {
            top: 190px;
        }
    }

    .menu-search-hidden {
        .cobrand-banner {
            @media (max-width: 767px) {
                top: -120px;
            }

            @media (min-width: 768px) and (max-width: 1021px) {
                top: -124px;
            }
        }

        .mobile-sort-and-filter-wrapper {
            top: -120px;

            @media (min-width: 768px) and (max-width: 1021px) {
                top: -124px;
            }
        }
    }

    #innerfiltercontainer {
        @media (min-width: 1022px) {
            top: 242px !important;
            height: calc(100vh - 242px);
        }
    }

    #FiltersHeader {
        top: 0;

        @media (min-width: 1022px) {
            top: 196px;
        }
    }

    .HeadnCount {
        padding-top: calc(24px - calc(4 * var(--padding-unit, 4px)));

        @media (min-width: 1022px) {
            padding-top: 0;
        }
    }
}

.brand-sportsdirect {
    --brand-banner-background: var(--fras-black);

    [data-brand="SportsDirect"] {
        --logo-display: "block";
        --brand-logo-width: 92px;
    }
}

.brand-flannels {
    --brand-banner-background: #eaff00;

    [data-brand="Flannels"] {
        --logo-display: "block";
        --brand-logo-width: 110px;
    }
}

.brand-game {
    --brand-banner-background: var(--brand-game-black);

    [data-brand="Game"] {
        --logo-display: "block";
        --brand-logo-width: 110px;
    }
}

.brand-evans {
    --brand-banner-background: #005a54;

    [data-brand="EvansCycles"] {
        --logo-display: "block";
        --brand-logo-width: 110px;
    }
}

.brand-jackwills {
    --brand-banner-background: #20419a;
    [data-brand="JackWills"] {
        --logo-display: "block";
        --brand-logo-width: 110px;
    }
}

.brand-hermes {
    --brand-banner-background: var(--fras-black);

    [data-brand="Hermes"] {
        --logo-display: "block";
        --brand-logo-width: 133px;
    }
}

.brand-dior {
    --brand-banner-background: var(--fras-black);

    [data-brand="Dior"] {
        --logo-display: "block";
        --brand-logo-width: 135px;
    }
}

.brand-chanel {
    --brand-banner-background: var(--fras-white);

    .cobrand-banner {
        padding: 0;
    }

    [data-brand="Chanel"] {
        --logo-display: "block";
        --brand-logo-width: 164px;
        margin-bottom: 0;
    }

    &.brand-shop-all .HeadnCount {
        display: none;
    }

    .ImgTxtContainer {
        border-top: 10px solid #000;
    }
}

.plp-cobrands-active.brand-chanel .topheadbox {
    max-width: 1920px;
    padding: 0;
}

.plp-cobrands-active.brand-chanel .cobrand-banner {
    margin: 10px 0 0;
    max-width: 1920px;
}

.plp-cobrands-active.brand-chanel .HeadnCount,
.plp-cobrands-active.brand-chanel #lblCategoryCopy {
    max-width: var(--large-container-max-width);
    margin: 0 auto;
}
.plp-cobrands-active.brand-chanel .cobrand-banner {
    border-top: 10px solid #000;
    margin-top: 10px;
}

.brand-chanel .ImgTxtContainer {
    border-top: none;
}
