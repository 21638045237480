.LogoWrap {
    img {
        max-width: 100px;
        max-height: 18px;
        width: 100%;

        @media (min-width: 361px) {
            max-height: 22px;
            max-width: 130px;
        }

        @media (min-width: 768px) {
            max-height: 24px;
            display: inline-block;
            max-width: 138px;
        }
    }

    a {
        line-height: 35px;
        text-align: left;

        @media (min-width: 768px) {
            width: auto;
            line-height: 55px;
            display: flex;
            align-items: center;
            max-width: 138px;
        }
    }
}

@media (min-width: 768px) {
    .TopNavBar .LogoWrap {
        padding: 10px 5px 10px 20px;
    }
}

@media (max-width: 767px) {
    .TopNavBar .ToplinksGroup .row > .LogoWrap {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        width: calc(100% - 210px);
    }
}
