.sdDetails .AltProdDet .pdpPriceRating {
    padding: 0;

    .pdpPrice {
        #lblSellingPrice {
            font-size: var(--body-font-size);
            font-weight: var(--font-weight-bold);
        }
    }

    #TicketPriceDiv2 {
        font-size: 15px;
        color: var(--fras-black);
    }
}

.pdpPriceRating {
    margin-bottom: 28px;
}
