.sdDetails .AltProdDet .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
        padding: 0;
    }

    .brandTitle .brandLink {
        font-size: var(--subtitle-font-size);
        font-weight: var(--font-weight-regular);
        padding: 0;

        span {
            color: var(--fras-dark-grey);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .prodTitle {
        font-size: var(--body-font-size);
        font-weight: var(--font-weight-regular);
        color: var(--fras-dark-grey);
        padding-bottom: 4px;
    }
}

.sdDetails .AltProdDet .title h1 > span {
    padding-bottom: 4px;
}
