.sdlProdList {
    .member-pricing {
        margin-top: -6px;
        margin-bottom: 3px;

        .member-price-text {
            font-size: 16px;
        }
    }
}

.sdDetails {
    .member-price {
        justify-content: start;
        text-align: left;
        font-size: var(--body-font-size);

        + .pdpPriceRating {
            padding-top: 0;
        }

        .member-price-label {
            font-weight: var(--font-weight-bold);
        }
    }
}

.member-pricing .member-price-text,
.member-pricing .member-price-value,
.ProdDetails .member-price {
    font-family: var(--default-font-family);
}
