.modal,
#hotspotModal.modal,
#GwpPromotionDetailModal.modal {
    .modal-header {
        font-weight: var(--font-weight-bold);
        font-size: var(--body-font-size);
        padding: 15px 40px 15px 15px;
        position: relative;
        background-color: var(--fras-white);
    }

    .modal-header .text-center {
        text-align: left;
        line-height: normal;
        display: block;
    }

    .modal-header .text-center .header-text {
        font-size: var(--subtitle-font-size);
        font-weight: var(--font-weight-bold);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        height: 100%;
        line-height: normal;
        display: flex;
        align-items: center;
    }

    .modal-header .close {
        background-image: url(/images/core/frasers-icons-v2.svg) !important;
        content: "";
        display: inline-block;
        background-size: 900px 900px !important;
        cursor: pointer;
        background-color: transparent !important;
        border: 0;
        right: 5px;
        top: 5px;
        width: 39px;
        height: 39px;
        background-position: -535px -426px !important;
        translate: 0;
    }

    .modal-content {
        border: 0;
    }
}

@media (max-width: 768px) {
    .modal-backdrop.in {
        z-index: 5910;
    }
}
