:root {
    /* Search autocomplete spacer */
    --autocomplete-top: 0;
    /* Primary colours */
    --site-black: #000;
    --fras-black: #000;
    --fras-gold: #ac9751;
    --fras-warm-grey: #bfb7b0;
    --fras-mid-grey: #c4c4c4;
    --fras-warm-light-grey: #f2f1ef;
    --fras-dark-grey: #5e5e5e;
    --fras-brown-grey: #99928d;
    --fras-white: #fff;
    /* Secondary colours */
    --fras-pale-pink: #e1bbb4;
    --fras-dusky-pink: #ebb8c3;
    --fras-pale-green: #a7bdb1;
    --fras-mid-green: #86978e;
    --fras-dark-green: #96aa9f;
    --fras-violet: #d5c3d5;
    --fras-ice-blue: #bbdde6;
    --fras-off-white: #fdfcf7;
    --site-secondary-darker: #99928d;
    /* Tertiary colours */
    --fras-coral: #ff7477;
    --fras-neon-pink: #ff48b0;
    --fras-neon-green: #3bd23d;
    --fras-neon-blue: #009ccd;
    --fras-red: #ed0000;
    /* Brands specific styling */
    --brand-game-black: #000;
    /* Buttons */
    --button-font-color: var(--site-black);
    --button-background-color: var(--fras-white);
    --button-border-color: var(--site-black);
    --button-hover-font-color: var(--site-black);
    --button-hover-background-color: var(--fras-warm-grey);
    --button-hover-border-color: var(--fras-warm-grey);
    /* Spacing */
    --padding-unit: 4px;
    --spacing-unit: 4px;
    --border-radius: 4px;
    --default-letter-spacing: 0;
    /* Fonts */
    --body-font-size: 14px;
    --subtitle-font-size: 15px;
    --font-weight-light: 300;
    --font-weight-regular: normal;
    --font-weight-bold: 700;
    --default-font-family: lato, arial, helvetica, sans-serif;
    --secondary-font-family: lato, arial, helvetica, sans-serif;
    /* Widths */
    --large-container-max-width: 1600px;
    --default-container-max-width: 1400px;
    /* Spritesheets */
    --spritesheet: url("/images/core/frasers-icons-v4.svg");
    /* Elevated Cart Variables */
    --cart-font-family: var(--default-font-family);
    --cart-font-family-bold: var(--default-font-family);
    --cart-font-family-italic: var(--default-font-family);
    --cart-sticky-element-default-top-value: 132px;
    --cart-details-margin-top-value: 32px;
    --cart-heading-font-style: normal;
    --cart-heading-font-family: var(--default-font-family);
    --cart-button-background-color: var(--fras-warm-grey);
    --cart-button-font-color: var(--fras-black);
    --cart-button-hover-color: var(--fras-brown-grey);
    --cart-button-visited-color: var(--fras-black);
    --cart-button-font-style: normal;
    --cart-button-font-family: var(--default-font-family);
    --cart-button-font-weight: var(--font-weight-bold);
    --cart-button-transactional-background-color: var(--fras-pale-green);
    --cart-button-transactional-hover-background-color: var(--fras-mid-green);
    --cart-button-transactional-font-color: #000;
    --cart-total-font-weight: var(--font-weight-bold);
    --cart-error-font-color: var(--fras-red);
    --cart-discount-font-color: var(--fras-red);
    --cart-error-background-color: hsl(0deg 100% 98% / 1);
    --cart-background-color: #f7f7f7;
    /* Wishlist Variables */
    --wishlist-summary-header-font-style: normal;
    --wishlist-header-font-style: normal;
    --wishlist-summary-header-text-transform: capitalize;
    --wishlist-button-font-weight: normal;
    --wishlist-button-letter-spacing: var(--default-letter-spacing);
    --wishlist-scroll-background-color: var(--fras-brown-grey);
    --product-line-item-price-font-weight: normal;
    --wishlist-button-letter-spacing: 0;
    --product-line-item-price-font-weight: var(--font-weight-regular);
    --product-line-item-line-error: var(--fras-red);
    --product-line-item-font-family-bold: var(--default-font-family);
    --product-line-item-discount-text-color: var(--fras-red);
    --site-membership-badge-background-colour: var(--fras-warm-grey);
    --site-membership-badge-text-colour: var(--fras-black);

    --printess-pdp-tick-outer: var(--fras-warm-grey);
    --printess-pdp-tick-inner: var(--fras-black);
}
