.OrderComplete.Checkout22 a.button,
.OrderComplete.Checkout22 a.button:visited {
    background-color: var(--fras-pale-green) !important;
    border-color: var(--fras-pale-green);
    color: var(--site-black);

    &:hover {
        background-color: var(--fras-mid-grey) !important;
    }
}

.OrderComplete {
    font-family: var(--default-font-family);

    .orderInformationWrapper {
        .order-complete-messages {
            font-family: var(--secondary-font-family);

            h1 {
                font-family: var(--secondary-font-family);
                font-style: normal;
                font-weight: var(--font-weight-regular);
            }

            .pii-item {
                font-size: var(--body-font-size);
            }

            a.button {
                font-family: var(--secondary-font-family);
                background-color: var(--fras-warm-grey);
                color: var(--fras-black);
                font-style: normal;
                border: 0;
                text-transform: capitalize;
                display: block;
                line-height: normal;
                letter-spacing: normal;
                max-width: 320px;
                border-radius: 2px;
                padding: 15px;

                &:hover {
                    background-color: var(--fras-mid-grey) !important;
                }
            }
        }

        .order-details-wrapper {
            .order-details-section {
                h2 {
                    font-family: var(--secondary-font-family);
                    font-weight: var(--font-weight-bold);
                }

                .order-details-title {
                    font-size: var(--subtitle-font-size);
                    font-weight: var(--font-weight-regular);
                }

                p {
                    font-size: var(--body-font-size);
                    font-weight: var(--font-weight-regular);
                }

                .order-details-delivery-date span {
                    color: var(--fras-black);
                    font-style: normal;
                }
            }
        }
    }

    .order-summary {
        h2 {
            font-family: var(--secondary-font-family);
        }
    }

    .RegisterWrapper {
        padding-top: 0;

        .RegisterWrapper-inner {
            font-family: var(--default-font-family);

            .OrderCompleteRegistration {
                .OrderCompleteRegistrationForm {
                    h2 {
                        font-family: var(--secondary-font-family);
                    }

                    input[type="submit"] {
                        background-color: var(--fras-warm-grey);
                        color: var(--fras-black);
                        font-style: normal;
                        border: 0;
                        text-transform: uppercase;
                        display: block;
                        line-height: 1em;
                        letter-spacing: normal;
                        max-width: 320px;
                        border-radius: 2px;
                        padding: 15px;

                        @media (min-width: 768px) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.RegisterWrapper {
    padding-top: 0 !important;
}

.RegisterWrapper .RegisterWrapper-inner {
    font-family: var(--default-font-family);
}

.footer-nav {
    justify-content: center;
}

.OrderCompleteRegistration .SignUpLabel {
    font-size: var(--body-font-size);
}

.Checkout22 .OrderCompleteRegistration .ContinueOn {
    font-family: var(--secondary-font-family);
    background-color: var(--fras-warm-grey);
    color: var(--fras-black);
    font-style: normal;
    border: 0;
    text-transform: capitalize;
    display: block;
    line-height: normal;
    letter-spacing: normal;
    border-radius: 2px;
    padding: 15px;
    max-width: 230px;
}

.OrderCompleteRegistration input[type="submit"].ContinueOn:hover {
    background-color: var(--fras-mid-grey) !important;
    color: var(--fras-black) !important;
}

.OrderComplete.Checkout22 a.button.secondary {
    font-family: var(--secondary-font-family);
    font-style: normal;
    background-color: var(--fras-white) !important;
    border: 1px solid var(--fras-black);
    text-transform: capitalize;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader h2 {
    font-size: var(--body-font-size);
}

.order-summary-table {
    text-align: left;
}

.order-summary .st1 {
    fill: var(--fras-black) !important;
}

.order-summary .product-gallery .swiper-button-next:focus,
.order-summary .product-gallery .swiper-button-next:hover,
.order-summary .product-gallery .swiper-button-prev:focus,
.order-summary .product-gallery .swiper-button-prev:hover {
    background-color: var(--fras-black) !important;

    .st1 {
        fill: var(--fras-white) !important;
    }
}

.registration-benefit .global-icon svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

@media (max-width: 767px) {
    .OrderComplete.Checkout22 a.button.secondary {
        width: 100%;
    }

    .Checkout22 .OrderCompleteRegistration .ContinueOn {
        max-width: 100%;
    }

    .OrderCompleteRegistrationForm input[type="submit"] {
        max-width: 100%;
    }

    .OrderComplete .orderInformationWrapper .order-complete-messages a.button {
        max-width: 100%;
    }
}
