.sdDetails .ContentWrapper,
.sdDetails .BreadcrumbGroupWrapper,
.sdDetails .sdPageProductContainer {
    background: var(--fras-white);
}

.sdDetails {
    max-width: 100%;
}

.sdDetails .ContentWrapper {
    margin: 0 auto !important;
    padding: 0;
    padding-bottom: calc(var(--padding-unit) * 11.5);

    .ResponsiveProductDetail {
        padding: 0 calc(var(--padding-unit) * 4);
        max-width: var(--default-container-max-width);
        margin: 0 auto;

        @media (min-width: 1022px) {
            padding: 0 calc(var(--padding-unit) * 10);
        }

        @media (min-width: 1640px) {
            max-width: var(--large-container-max-width);
            padding: 0;
        }
    }

    #BreadcrumbGroup {
        padding: calc(var(--padding-unit) * 4);
        max-width: var(--default-container-max-width);
        margin: 0 auto;
        float: none;

        @media (min-width: 1022px) {
            padding: calc(var(--padding-unit) * 6) calc(var(--padding-unit) * 10);
        }

        @media (min-width: 1640px) {
            max-width: var(--large-container-max-width);
            padding: calc(var(--padding-unit) * 6) 0;
        }
    }

    .s-breadcrumbs-bar {
        padding: 0;
    }

    @media (max-width: 767px) {
        .ResponsiveProductDetail {
            padding: 0;
        }

        .ProdDetLeft {
            padding: calc(var(--padding-unit) * 2.5) calc(var(--padding-unit) * 4) 0;
        }
    }
}

.sdPageProductContainer .ColourImagesWrap,
.AltProdDet #productDetails .productVariantContainer > div {
    z-index: 1 !important;
}

@media (min-width: 768px) {
    .sdDetails #productImages {
        width: 60%;
        padding: 0;
    }

    .sdDetails #productDetails {
        width: 40%;
        padding-left: 24px;
    }
}

@media (min-width: 1022px) {
    .sdDetails #productImages {
        padding: 0;
    }

    .sdDetails #productDetails {
        padding-left: 40px;
    }
}

@media (min-width: 1440px) {
    .sdDetails #productImages {
        width: 70%;
    }

    .sdDetails #productDetails {
        width: 30%;
    }
}

.colmask,
.sdDetails #productDetails {
    float: none;
}

.productVariantContainer .SzQuantGroup .sizeSelectWrap {
    position: relative;
}

.sdDetails #productDetails .productVariantContainer {
    .colourChooser,
    .ColourImagesWrap,
    .SzQuantGroup,
    #availableFromContainer,
    .BasketWishContainer {
        padding: 0;
    }

    .BasketWishContainer,
    .s-basket-quantity-text-box:not(.WishListProductQuantityControls .s-basket-quantity-text-box),
    #divColour,
    .ColourImagesWrap {
        float: none;
    }

    .colourChooser,
    .SzQuantGroup {
        padding-bottom: calc(var(--padding-unit) + 1);
    }

    #availableFromContainer {
        padding-top: calc(var(--padding-unit) * 4);
        float: none;
    }
}

.s-breadcrumbs-bar > span ol li a:focus,
.s-breadcrumbs-bar > span ol li a:hover {
    color: var(--fras-gold);
    text-decoration: underline;
}

.s-breadcrumbs-bar > span ol li {
    color: var(--fras-black);
    padding: 0;
}

.s-breadcrumbs-bar a,
.s-breadcrumbs-bar a:active,
.s-breadcrumbs-bar a:focus,
.s-breadcrumbs-bar a:hover,
.s-breadcrumbs-bar a:visited {
    color: var(--fras-black);
}

.stock-level-container {
    border-radius: 0;
    margin: 16px 0;
}

@media (max-width: 767px) {
    #BodyWrap .ContentWrapper {
        padding-bottom: 64px;
    }

    .sdDetails .productImageContentWrapper {
        display: flex;
        flex-direction: column;
    }

    .sdDetails .productImageContentWrapper > div {
        order: 2;
    }

    .sdDetails .productImageContentWrapper .promo-header-pdp {
        order: 1;
    }
}
.s-breadcrumbs-container {
    margin: 0;
}

.sdDetails #Popup360ButtonWrapper #Popup360Label {
    font-size: var(--subtitle-font-size);
    top: 53%;
    line-height: 1.1;
}

.s-basket-quantity-text-box input::-webkit-outer-spin-button,
.s-basket-quantity-text-box input::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
}

.sdDetails #MoreFromLinks li.MoreFromLinksRow a {
    letter-spacing: var(--default-letter-spacing);
}
