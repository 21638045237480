.infoaccordions .panel-heading h4 {
    font-family: var(--default-font-family);
    padding: 0;
    font-size: var(--body-font-size) !important;
    position: relative;
    color: var(--fras-black);
}

.infoaccordions .panel-heading h4::after {
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    background-image: var(--spritesheet);
    background-size: 840px 840px;
    background-position: -409px -405px;
    position: absolute;
    right: 0;
    top: 0;
}

.infoaccordions .panel-heading .collapsed h4::after {
    background-position: -374px -405px;
}

.infoaccordions {
    #DisplayAttributes li span,
    #deliveryreturns #deliveryDetails li span,
    p,
    .SpendVoucher {
        font-size: var(--body-font-size);
        color: var(--fras-black);
    }

    .productDescriptionInfoText {
        font-size: var(--body-font-size);
    }

    .productDescriptionInfoText a {
        color: var(--fras-black);

        &:hover {
            color: var(--fras-black);
        }
    }

    #deliveryDetails {
        .DeliveryMethod {
            margin-bottom: 20px;
            justify-content: space-between;

            span.TitleDel {
                line-height: 15px;
                padding-bottom: var(--padding-unit);
                font-weight: var(--font-weight-bold);
            }
        }

        .DeliveryMethodDescription {
            font-size: var(--body-font-size);
        }
    }

    #deliveryreturns {
        .returnsMethod {
            font-size: var(--body-font-size);

            h4 {
                font-size: var(--body-font-size);
                font-weight: var(--font-weight-bold);
                line-height: 15px;
                padding-bottom: var(--padding-unit);
            }

            a {
                color: var(--fras-black);
                text-decoration: underline;
            }
        }
    }

    .pdp-delivery-message {
        margin-bottom: 20px;
    }
}

.delivery_nextDate {
    display: none;
}

#MoreFromLinks {
    #MoreFromLinksHeader {
        font-weight: var(--font-weight-bold) !important;
        font-size: var(--body-font-size) !important;
    }

    li.MoreFromLinksRow a {
        font-size: var(--body-font-size);
        font-weight: var(--font-weight-regular) !important;
    }
}

.productDescriptionInfoText {
    font-size: var(--body-font-size) !important;
}

.matchMyMakeupContainer {
    padding-bottom: 0;

    .MMMHeader {
        font-weight: var(--font-weight-bold);
        color: var(--site-black);
        font-size: var(--body-font-size);
        text-transform: none;
    }
    .MMMContent {
        font-size: var(--body-font-size);
        color: var(--site-black);
    }
}

.productVariantContainer:has(.SzQuantGroup.hidden) {
    .ColourImagesWrap {
        margin-bottom: 20px;
    }
}

.productVariantContainer .SzQuantGroup:not(.hidden) {
    margin-bottom: 20px;
}

#productDetails .TrueFitWrapper iframe {
    margin: 0;
}

.ProdDetails .swiper-container.swiper-container-pdpfeatures .swiper-slide {
    padding: 20px 0 0;
    width: auto !important;
}

.ProdDetails .swiper-container.swiper-container-pdpfeatures .swiper-slide .attribute-fresh span {
    width: auto;
}

@media (max-width: 767px) {
    .frasers-plus-breakdown {
        padding: 0 0 28px;
    }
}
