.SzQuantGroup .sizeButtons li a {
    border: 1px solid var(--fras-mid-grey);
    height: 40px;
    line-height: 40px;
    color: var(--fras-black);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.sdDetails .sizeButtons li a:hover,
.sdDetails .sizeButtons li a:focus {
    border: 2px solid var(--fras-gold);
    background: var(--fras-off-white);
}

.s-productextras-column-1 span,
.s-productextras-column-1 label,
.s-productextras-checkbox-column-1 label {
    font-weight: var(--font-weight-regular);
    letter-spacing: normal;
}

.SzQuantGroup {
    margin-top: 24px;
}

.SzQuantGroup .SizeGuideText {
    color: #728082;
    font-size: 12px;
    font-weight: var(--font-weight-regular);
}

.SzQuantGroup .sizeButtons li {
    padding: 5px;
    margin: 0;
    width: 33.333333%;
    height: auto;
    background-color: transparent;
    font-size: 1.2em;
    z-index: 10;
}

.SzQuantGroup .sizeButtons li:first-child:nth-last-child(2),
.SzQuantGroup .sizeButtons li:first-child:nth-last-child(2) ~ li {
    width: 50%;
}

.SzQuantGroup .sizeButtons li.sizeVariantHighlight::before {
    display: none;
}

.SzQuantGroup .sizeButtons li.sizeVariantHighlight a {
    border: 2px solid var(--fras-gold);
    background: var(--fras-off-white);
    color: var(--fras-black);
    font-weight: var(--font-weight-regular);
}

.SzQuantGroup .sizeButtons li a span {
    line-height: normal;
    pointer-events: none;
}

.SzQuantGroup .sizeButtons li.greyOut a {
    background: #f8f8f8;
    color: var(--fras-black);
    border: 1px solid #e7eaec !important;
}

.SzQuantGroup .sizeButtons li.greyOut a:hover,
.SzQuantGroup .sizeButtons li.greyOut a:focus {
    background: #f8f8f8;
    border: 1px solid #e7eaec;
}

#BuySizeText {
    font-size: var(--body-font-size);
    font-weight: var(--font-weight-bold);
    line-height: normal;
}

.swapSize .s-productextras-column-2 {
    padding-top: 0;
    margin-top: 3px;
}

#divPopupSizeGuide {
    .sizeslink {
        display: flex;

        .SizeGuideText {
            color: var(--fras-black);
            font-size: var(--body-font-size);
        }
    }
}

.s-productextras-column-1 {
    padding: 0;
    line-height: normal;
}

.swapColour .s-productextras-column-2-3 {
    padding: 0 5px 0;
}

.s-productextras-column-3 {
    top: 0;
}
