.StoreLocator {
    @media (max-width: 767px) {
        .StoreFinderResultsDetailsLinkContainer a {
            background: var(--fras-warm-grey) !important;
            color: var(--fras-black) !important;
            font-family: var(--secondary-font-family);
            text-transform: uppercase;
        }
    }

    h1,
    .StoreFinderResultsLink,
    .StoreFinderResultsLinkMobile {
        font-weight: var(--font-weight-bold);
        color: var(--fras-black);
    }

    .storefinderSearchButtons input {
        font-size: var(--subtitle-font-size);
    }
}
